<template>
  <div id="header" :class="toggleNavClass()">
    <div class="wrapper">
      <h1 class="hidden-title">Otium Soft</h1>
      <div class="logo">
        <router-link :to="{ path: '/' }">
          <img src="../assets/logo.png" alt="Otium Soft logo" loading="lazy"/>
        </router-link>
      </div>
      <template v-if="!mobileView">
        <v-navbar :menu_links="links" />
        <v-language-switcher
          class="desktop"
          :current_language="current_language"
          :language_items="language_items"
          :isMobile="false"
        />
      </template>
      <v-burger v-else />
    </div>
    <v-sidebar
      v-if="mobileView"
      :links="links"
      :current_language="current_language"
      :language_items="language_items"
    />
  </div>
</template>

<script>
import VBurger from "../components/Menu/VBurger";
import VSidebar from "./Menu/VSidebar.vue";
import VNavbar from "./Menu/VNavbar";
import VLanguageSwitcher from "./Menu/VLanguageSwitcher";

import { store, mutations } from "../store/app/store.js";

export default {
  name: "VHeader",
  components: { VBurger, VSidebar, VNavbar, VLanguageSwitcher },
  props: {
    links: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    mobileView: true,
    active: false,
    language_items: [
      { label: "English (ENG)", value: "https://otiumsoft.com/" },
      { label: "Polski (POL)", value: "https://otiumsoft.pl/" }
    ],
    current_language: "RU"
  }),
  computed: {
    isBurgerActive() {
      return store.isNavOpen;
    }
  },
  methods: {
    toggleNavClass() {
       if (this.$route.path === "/") {
        return store.isHeaderScroll
          ? "header header-solid"
          : "header header-transparent";
      } else {
        return "header header-solid";
      }
    },
    handleView() {
      this.mobileView = window.innerWidth < 768;
      if (!this.mobileView && this.isBurgerActive) {
        mutations.toggleNav();
      }
    }
  },
  mounted() {
    window.document.onscroll = () => {
      let navBar = document.getElementById("header");
      window.scrollY > navBar.offsetTop
        ? mutations.activeHeaderScroll()
        : mutations.disactiveHeaderScroll();
    };
  },
  created() {
    this.handleView();
    window.addEventListener("resize", this.handleView);
  }
};
</script>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.hidden-title {
  position: absolute;
  overflow: hidden;
  height: 1px;
  width: 1px;
}

.logo {
  img {
    width: 81px;
    height: 32px;
    @include for-tablet-portrait-up {
      width: 111px;
      height: 44px;
    }
  }
}

.header {
  position: fixed;
  z-index: 12;
  top: 0;
  padding: 14px 10px;
  width: 100vw;
  max-width: 100%;
  transition: transform 0.2s linear, background 0.5s ease;
  @include for-tablet-portrait-up {
    padding: 14px 15px;
  }
  @include for-tablet-landscape-up {
    padding: 30px 16px;
  }
  @include for-desktop-up {
    padding: 30px 20px;
  }
}

.header-transparent {
  box-shadow: none;
  background-color: transparent;
  .language__switcher.desktop {
    .current-language__item {
      color: var(--white-color);
    }
  }
  .menu__link {
    color: var(--white-color);
  }
}

.header-solid {
  background: var(--white-color);
  box-shadow: var(--header-box-shadow);
  .menu__link {
    color: var(--black-color);
    &:hover {
      text-shadow: 0px 0px 0px rgba(0, 0, 0, 1);
    }
  }
  .burger-bar {
    background-color: rgb(0, 0, 0);
  }
  .language__switcher.desktop {
    .current-language__item {
      color: var(--black-color);
    }
  }
}

.menu__link-mobile {
  @include P2();
  @include header-link-mobile();
  color: var(--white-color);
}
</style>
