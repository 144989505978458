<template>
  <div class="services" id="services">
    <div class="wrapper">
      <div class="services__wrapper">
        <div class="services__header" data-aos="fade-up">
          <h6 class="services__subtitle subtitle">Услуги</h6>
          <h2 class="services__title title">Что мы делаем</h2>
        </div>
        <div class="services__content">
          <v-two-sliders
            :services_settings="sliderServicesSettings"
            :images_settings="sliderImagesSettings"
          >
            <template v-slot:text-slider>
              <div
                class="slick-item"
                v-for="(slider_item, index) in sliderServices"
                :key="index"
              >
                <div class="slider-item__title">
                  {{ slider_item.title }}
                </div>
                <div class="slider-item__subtitle">
                  {{ slider_item.subtitle }}
                </div>
                <div
                  v-if="slider_item.direction"
                  class="slider-item__direction"
                >
                  {{ slider_item.direction }}
                </div>
                <div
                  v-if="slider_item.technology_stack_name"
                  class="slider-item__stack-name"
                >
                  {{ slider_item.technology_stack_name }}
                </div>
                <div
                  v-if="slider_item.description"
                  class="slider-item__description"
                >
                  {{ slider_item.description }}
                </div>
                <div
                  class="slider-item__description"
                  v-if="slider_item.calculator_link"
                >
                  Нажмите
                  <a
                    :href="slider_item.calculator_link"
                    class="slider-item__calculator-link"
                    target="_blank"
                    >здесь</a
                  >
                  , чтобы рассчитать стоимость разработки вашего приложения
                </div>
                <ul
                  class="slider-item__technology-stack"
                  v-if="slider_item.technology_stack"
                >
                  <li v-for="item in slider_item.technology_stack" :key="item">
                    <span>{{ item }}</span>
                  </li>
                </ul>
              </div>
            </template>
            <template v-slot:image-slider>
              <div
                class="slick-item"
                v-for="(slider_item, index) in sliderImages"
                :key="index"
              >
                <picture>
                  <source
                    :srcset="
                      require('../assets/images/services/' +
                        slider_item.img +
                        '.jpg') +
                        ' 1x, ' +
                        require('../assets/images/services/' +
                          slider_item.img +
                          '_2x' +
                          '.jpg') +
                        ' 2x'
                    "
                    type="image/jpeg"
                    media="(min-width: 768px)"
                  />
                  <source
                    :srcset="
                      require('../assets/images/services/' +
                        slider_item.img +
                        '_mob' +
                        '.jpg') +
                        ' 1x, ' +
                        require('../assets/images/services/' +
                          slider_item.img +
                          '_mob_2x' +
                          '.jpg') +
                        ' 2x'
                    "
                    type="image/jpeg"
                    media="(max-width: 767px)"
                  />
                  <img
                    :src="
                      require('../assets/images/services/' +
                        slider_item.img +
                        '.jpg')
                    "
                    alt="Service"
                    loading="lazy"
                  />
                </picture>
              </div>
            </template>
          </v-two-sliders>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VTwoSliders from "./Carousel/VTwoSliders";
export default {
  name: "VServices",
  components: { VTwoSliders },
  data() {
    return {
      sliderImages: [
        {
          id: 1,
          img: "service_mobile_1",
          alt: "Мобильная разработка"
        },
        {
          id: 2,
          img: "service_devops_2",
          alt: "DevOps"
        },
        {
          id: 3,
          img: "service_frontend_3",
          alt: "Веб-разработка Front-end"
        },
        {
          id: 4,
          img: "service_backend_4",
          alt: "Веб-разработка backend"
        },
        {
          id: 5,
          img: "service_web_solutions_5",
          alt: "Веб решения"
        },
        {
          id: 6,
          img: "service_cloud_6",
          alt: "Облачные сервисы"
        },
        {
          id: 7,
          img: "service_testing_7",
          alt: "Тестирование"
        },
        {
          id: 7,
          img: "service_design_8",
          alt: "Дизайн"
        }
      ],
      sliderServices: [
        {
          id: 1,
          title: "Разработка мобильных приложений",
          subtitle: "Услуга #1",
          description:
            "Проектируем, разрабатываем, тестируем и готовим к релизу нативные и кросс-платформенные мобильные приложения для платформ iOS и Android.",
          calculator_link: "https://calc.otiumsoft.com/"
        },
        {
          id: 2,
          title: "DevOps",
          subtitle: "Услуга #2",
          technology_stack: [
            "Консультрование по внедрению DevOps",
            "Мониторинг и резервное копирование",
            "Автоматизация и управление конфигурацией Devops",
            "Внедрение сторонних программных продуктов/решений",
            "Continuous Integration & Continuous Delivery",
            "Управление релизами проектов",
            "Обслуживание и поддержка"
          ]
        },
        {
          id: 3,
          title: "Веб-разработка",
          subtitle: "Услуга #3",
          direction: "Front-end разработка",
          technology_stack_name: "Технологический стек:",
          technology_stack: [
            "Языки программирования: JavaScript, HTML5, CSS3",
            "JavaScript фреймворки: Vue.js",
            "Кроссплатформенные: Flutter"
          ]
        },
        {
          id: 4,
          title: "Веб-разработка",
          subtitle: "Услуга #3",
          direction: "Back-end разработка",
          technology_stack_name: "Технологический стек:",
          technology_stack: [
            "Языки и фреймворки: Go (golang)",
            "Облачные платформы: Digital Ocean, Amazon EC2, Microsoft Azure, Heroku, Google App Engine",
            "Базы данных: PostgreSQL, MySQL"
          ]
        },
        {
          id: 5,
          title: "Веб-решения",
          subtitle: "Услуга #3",
          technology_stack: [
            "Сайты",
            "Одностраничные приложения (SPA)",
            "Прогрессивные веб-приложения (PWA)",
            "Системы для автоматизации бизнес-процессов (ERP, CRM и др.)",
            "Веб-приложения с использованием технологий искусственного интеллекта и алгоритмов машинного обучения",
            "Чат-боты",
            "Социальные сети"
          ]
        },
        {
          id: 6,
          title: "Разработка облачных сервисов",
          subtitle: "Услуга #4",
          technology_stack: [
            "PaaS",
            "SaaS",
            "Облачные приложения (Amazon, Azure)",
            "Архитектура облачных вычислений",
            "Управление данными в облаке"
          ]
        },
        {
          id: 7,
          title: "Обеспечение качества & Тестирование",
          subtitle: "Услуга #5",
          technology_stack: [
            "Ручное тестирование: функциональное и нефункциональное",
            "Автоматизированное тестирование",
            "UI тестирование",
            "Юнит-тестирование",
            "Интеграционное тестирование"
          ]
        },

        {
          id: 8,
          title: "Дизайн",
          subtitle: "Услуга #6",
          technology_stack: [
            "Дизайн мобильных приложений ",
            "Веб-дизайн",
            "UX - исследование",
            "Прототипирование",
            "Визуальный дизайн",
            "Графический дизайн",
            "Создание брендбуков"
          ]
        }
      ],
      sliderImagesSettings: {
        dots: false,
        slidesToScroll: 1,
        slidesToShow: 1,
        infinite: true,
        variableWidth: true,
        touchMove: false,
        arrows: false
      },
      sliderServicesSettings: {
        adaptiveHeight: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        arrows: true
      }
    };
  }
};
</script>

<style lang="scss">
.services {
  background: var(--light-color);
  display: flex;
  overflow: hidden;
  &__wrapper {
    width: 100vw;
    max-width: 100%;
    padding: 34px 10px 114px;
    display: flex;
    flex-direction: column;
    @include for-tablet-portrait-up() {
      padding: 70px 16px;
    }
    @include for-tablet-landscape-up() {
      padding: 90px 16px;
    }
    @include for-desktop-up() {
      padding: 120px 0px;
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    @include for-tablet-portrait-up() {
      margin-bottom: 36px;
    }
    @include for-tablet-landscape-up() {
      margin-bottom: 40px;
    }
    @include for-desktop-up() {
      margin-bottom: 75px;
    }
    .title {
      text-align: center;
      color: var(--black-color);
    }
    .subtitle {
      color: var(--gray-color);
      text-align: center;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100vw;
    @include for-tablet-portrait-up() {
      flex-direction: row;
    }
  }
  .sliders__container {
    width: 100vw;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    @include for-tablet-portrait-up {
      width: 100%;
      flex-direction: row;
    }
    .sliders__container-item.text {
      width: calc(100vw - 20px);
      margin-bottom: 26px;
      @include for-tablet-portrait-up {
        width: 40%;
        padding-right: 15px;
        margin-bottom: 0;
      }
      @include for-desktop-up {
        width: 43%;
        padding-right: 16px;
      }
    }
    .text-slider {
      max-width: calc(100vw - 25px);
      height: 100%;
      @include for-tablet-portrait-up() {
        max-width: 100%;
      }
      .slick {
        &-slide {
          height: 0;
          max-width: calc(100vw - 25px);
          visibility: hidden;
        }
        &-active {
          height: auto;
          visibility: visible;
        }
        &-item {
          outline: none;
          &:active {
            outline: none;
          }
          &:focus {
            outline: none;
          }
        }
        &-arrow {
          @include carousel__btn-sm();
          position: absolute;
          top: calc(100% + 236px);
          @include for-tablet-portrait-up {
            top: calc(100% - 32px);
          }
          &.slick-prev {
            left: 0;
          }
          &.slick-next {
            left: 40px;
            @include for-tablet-portrait-up {
              left: 58px;
            }
          }
        }
      }
      .slider-item__title {
        margin-bottom: 12px;
        @include H6-bold();
        color: var(--dark-blue-color);
        @include for-tablet-portrait-up() {
          margin-bottom: 18px;
          @include P1-bold();
        }
        @include for-tablet-landscape-up {
          margin-bottom: 10px;
          @include H4-bold();
        }
        @include for-desktop-up() {
          margin-bottom: 8px;
          @include H3-bold();
        }
      }
      .slider-item__subtitle {
        @include P6();
        color: var(--dark-blue-color);
        display: inline-block;
        padding: 4px 13px;
        border-radius: 20px;
        text-align: center;
        background: var(--super-light-blue-color);
        margin-bottom: 20px;
        @include for-tablet-landscape-up {
          margin-bottom: 24px;
        }
      }
      .slider-item__direction {
        @include P3-bold();
        color: var(--dark-blue-color);
        margin-bottom: 12px;
        @include for-tablet-portrait-up {
          margin-bottom: 18px;
        }
        @include for-tablet-landscape-up {
          @include P2-bold();
          margin-bottom: 16px;
        }
      }
      .slider-item__stack-name {
        @include P4();
        color: var(--black-color);
        margin-bottom: 25px;
        @include for-tablet-portrait-up {
          margin-bottom: 12px;
        }
      }
      .slider-item__technology-stack {
        @include P4();
        list-style-type: disc !important;
        list-style-position: outside !important;
        color: var(--dark-blue-color);
        padding: 0 0 0 17px !important;
        li {
          margin-bottom: 10px;
          span {
            color: var(--black-color);
          }
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
      .slider-item__description + .slider-item__description {
        margin-top: 10px;
      }
      .slider-item__description {
        @include P4();
        color: var(--black-color);
      }
      .slider-item__calculator-link {
        // text-decoration: none;
        @include P4();
        color: var(--dark-blue-color);
        &:hover {
          cursor: pointer;
        }
      }
    }
    .image-slider {
      margin-left: -5px;
      .slick {
        &-slide {
          padding: 0 5px;
          height: 100%;
          @include for-tablet-portrait-up() {
            padding: 0 6px;
          }
          @include for-tablet-landscape-up() {
            padding: 0 8px;
          }
          @include for-desktop-up() {
            padding: 0 10px;
          }
        }
        &-item {
          position: relative;
          width: 100%;
          height: 180px;
          min-height: 180px;
          max-width: 270px;
          min-width: 270px;
          outline: none;
          overflow: hidden;
          border-radius: 5px;

          @include for-tablet-portrait-up() {
            max-width: 361px;
            min-width: 361px;
            min-height: 425px;
            border-radius: 10px;
          }
          @include for-tablet-landscape-up() {
            min-height: 376px;
            max-width: 421px;
            min-width: 421px;
          }
          @include for-desktop-up() {
            max-width: 692px;
            min-width: 692px;
            min-height: 376px;
          }
          img,
          source {
            height: 100%;
            max-width: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
            left: 0;
            @include for-tablet-portrait-up {
              width: 100%;
            }
          }
        }
        &-list {
          padding: 0 25% 0 0 !important;
          @include for-tablet-portrait-up() {
            padding: 0 20% 0 0 !important;
          }
          @include for-tablet-landscape-up() {
            padding: 0 20% 0 0 !important;
          }
          @include for-tablet-landscape-up() {
            padding: 0 20% 0 0 !important;
          }
        }
        &-arrow {
          position: absolute;
          top: calc(100% + 25px);
          @include carousel__btn-sm();
          @include for-tablet-portrait-up {
            top: calc(100% - 32px);
          }
          &.slick-prev {
            left: 0;
          }
          &.slick-next {
            left: 40px;
            @include for-tablet-portrait-up {
              left: 58px;
            }
          }
        }
      }
    }
  }
}
</style>
