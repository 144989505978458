<template>
  <div class="about-us" id="about-company" style="overflow: hidden">
    <div class="wrapper">
      <div class="about-us__wrapper">
        <div class="about-us__header" data-aos="fade-up">
          <h6 class="about-us__subtitle subtitle">О нас</h6>
          <h2 class="about-us__title title">О компании</h2>
        </div>
        <div class="about-us__text">
          <div class="about-us__column" data-aos="fade-right">
            <p>
              <span>OtiumSoft</span> — компания-разработчик кастомных
              программных решений.
            </p>
            <p>
              В нашей команде опыт молодых IT специалистов (back-end и front-end
              разработчиков, QA, дизайнеров) с десятками реализованных проектов
              за плечами органично сочетается со знаниями специалистов, которые
              более 5-ти лет успешно руководили командами в сферах продаж,
              логистики и др. Поэтому в большинстве случаев мы не понаслышке
              знаем о бизнес-запросах, с которыми вы обращаетесь, а главное –
              понимаем, как помочь эффективно решить их с помощью технологий.
            </p>
          </div>
          <div class="about-us__column" data-aos="fade-left">
            Мы не боимся сложных задач, готовы рассмотреть самые смелые ваши
            идеи и предложить варианты их технического воплощения в жизнь. При
            этом мы не будем «мучать» вас непонятными IT-терминами, так как
            умеем объяснять сложные вещи простым языком.
            <span>Основной принцип нашей работы</span> – прозрачность. На
            протяжении всего времени сотрудничества мы будем заботиться, чтобы
            вы были максимально осведомлены о статусе разработки вашего проекта
            на каждом из этапов. Мы предлагаем полный цикл разработки продукта с
            нуля, то есть вам не придётся тратить время на поиск отдельных
            специалистов для реализации каждого из этапов.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VAboutUs'
}
</script>

<style lang="scss" scoped>
.about-us {
  background-color: var(--darkest-gray-color);
  &__wrapper {
    padding: 40px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    @include for-tablet-portrait-up {
      padding: 50px 15px;
    }
    @include for-tablet-landscape-up {
      padding: 60px 16px;
    }
    @include for-desktop-up {
      padding: 70px 0;
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    align-items: center;
    @include for-tablet-portrait-up {
      margin-bottom: 36px;
    }
    @include for-tablet-landscape-up {
      margin-bottom: 40px;
    }
    @include for-desktop-up {
      margin-bottom: 50px;
    }
    .subtitle {
      color: var(--medium-gray-color);
    }
    .title {
      color: var(--white-color);
      text-align: center;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    color: var(--white-color);
    @include P4();
    line-height: 19px;
    @include for-tablet-portrait-up() {
      line-height: 20px;
    }
    @include for-tablet-landscape-up() {
      @include P3();
      line-height: 23px;
    }
    @include for-desktop-up() {
      @include P3();
      line-height: 24px;
    }
    p {
      margin-bottom: 10px;
      @include for-tablet-portrait-up {
        margin-bottom: 0;
      }
    }
    span {
      color: var(--light-blue-color);
    }
    @include for-tablet-portrait-up {
      flex-direction: row;
    }
  }
  &__column {
    @include for-tablet-portrait-up {
      width: calc((100% - 34px) / 2);
    }
    @include for-tablet-landscape-up {
      width: calc((100% - 40px) / 2);
    }
    @include for-desktop-up {
      width: calc((100% - 45px) / 2);
    }
  }
  &__column + &__column {
    @include for-tablet-portrait-up {
      margin-left: 34px;
    }
    @include for-tablet-landscape-up {
      margin-left: 40px;
    }
    @include for-desktop-up {
      margin-left: 45px;
    }
  }
}
</style>
