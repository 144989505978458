<template>
  <div class="managers" id="managers" style="overflow: hidden">
    <div class="wrapper">
      <div class="managers__wrapper">
        <div class="managers__card" data-aos="fade-up">
          <div class="managers__header">
            <h3 class="managers-form__title" data-aos="fade-up">
              Давайте обсудим вашу идею или проект
            </h3>
          </div>
          <div class="managers__body">
            <v-form class="managers__form" data-aos="fade-right"></v-form>
            <div class="managers__list" data-aos="fade-left">
              <div
                class="managers-item"
                v-for="(manager, index) in managers"
                :key="index"
              >
                <div class="managers-item__photo">
                  <img
                    v-if="manager.photo !== ''"
                    :src="require('../assets/images/managers/' + manager.photo + '.png')"
                    :srcset="require('../assets/images/managers/' + manager.photo + '.png') + ' 1x,' 
                           + require('../assets/images/managers/' + manager.photo + '_2x.png') + ' 2x,'"
                    alt="Manager photo"
                    loading="lazy"
                  />
                </div>
                <div class="managers-item__contacts">
                  <div class="managers-item__name">
                    {{ manager.name }}
                  </div>
                  <div class="managers-item__position">
                    {{ manager.position }}
                  </div>
                  <a
                    class="managers-item__phone"
                    :href="'tel:' + manager.phone"
                  >
                    {{ manager.phone }}
                  </a>
                  <a
                    class="managers-item__email"
                    :href="'mailto:' + manager.email"
                  >
                    {{ manager.email }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VForm from "../components/Form/VForm"

export default {
  name: "VManagersForm",
  data: () => ({
    managers: [
      {
        photo: "Siarhei_Chabarau_photo",
        name: "Сергей Чабаров",
        position: "Head of Sales",
        phone: "+375 (44) 549 42 90",
        email: "s.chabarau@otiumsoft.com"
      }
    ]
  }),
 components: { VForm },
};
</script>

<style lang="scss">
.managers {
  width: 100%;
  background: url("../assets/backgrounds/managers-form-background.jpg")
    no-repeat;
  background-position: center;
  background-size: cover;
  &__wrapper {
    width: 100%;
    padding: 40px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include for-tablet-portrait-up {
      padding: 40px 30px;
    }
    @include for-desktop-up {
      padding: 40px 0px;
    }
  }
  &__card {
    width: 100%;
    background: rgba(14, 14, 14, 0.5);
    backdrop-filter: blur(33px);
    border-radius: 20px;
    padding: 40px 12px;
    display: flex;
    flex-direction: column;
    @include for-tablet-portrait-up {
      padding: 50px 20px;
      max-width: 100%;
    }
    @include for-tablet-landscape-up {
      padding: 60px 40px;
    }
    @include for-desktop-up {
      padding: 90px 80px;
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    @include for-tablet-portrait-up {
      margin-bottom: 46px;
    }
    @include for-tablet-landscape-up {
      margin-bottom: 40px;
    }
    @include for-desktop-up {
      margin-bottom: 74px;
    }
    .managers-form__title {
      color: var(--white-color);
      text-align: center;
      @include P1();
      @include for-tablet-landscape-up {
        @include H4();
      }
      @include for-desktop-up {
        @include H3();
      }
    }
  }
  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    @include for-tablet-portrait-up {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &__form {
    width: 100%;
    display: flex;
    justify-content: center;
    @include for-tablet-portrait-up {
      width: calc(50% - 18px);
    }
    @include for-tablet-landscape-up {
      width: calc(50% - 37px);
    }
    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .form__group {
        @include form-group();
      }
      .form__group.form__group-checkbox {
        display: flex;
        flex-direction: row;
      }
      .label {
        @include form-label($white-color);
      }
      .label-checkbox {
        color: var(--white-color);
        a {
          color: var(--white-color);
        }
      }
      .checkbox{
        border: 1px solid var(--dark-gray-color);
      }
      input[type=text] {
        @include form-input($dark-gray-color, $white-color);
      }
      textarea {
        @include form-textarea($dark-gray-color, $white-color);
      }
      .form__group + .form__group {
        margin-top: 9px;
      }
    }
  }
  &__form + &__list {
    margin-top: 40px;
    @include for-tablet-portrait-up {
      margin-top: 0px;
      margin-left: 36px;
    }
    @include for-tablet-landscape-up {
      margin-left: 74px;
    }
    @include for-desktop-up {
      margin-left: 206px;
    }
  }
  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include for-tablet-portrait-up {
      justify-content: start;
      width: calc(50% - 18px);
    }
    @include for-tablet-landscape-up {
      width: calc(50% - 37px);
    }
  }
  &-item + &-item {
    @include for-tablet-portrait-up {
      margin-top: 20px;
    }
  }
  &-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include for-tablet-portrait-up {
      justify-content: start;
      flex-direction: row;
    }
    &__photo {
      width: 120px;
      min-width: 120px;
      height: 120px;
      min-height: 120px;
      border-radius: 50%;
      margin-bottom: 18px;
      background: #1f2229;
      @include for-tablet-portrait-up {
        margin-bottom: 0;
      }
      @include for-tablet-landscape-up {
        width: 150px;
        min-width: 150px;
        height: 150px;
        min-height: 150px;
      }
      img {
        width: 120px;
        height: 120px;
        @include for-tablet-landscape-up {
          width: 150px;
          height: 150px;
        }
      }
    }
    &__contacts {
      display: flex;
      flex-direction: column;
      text-align: center;
      @include for-tablet-portrait-up {
        text-align: left;
        margin-left: 16px;
      }
      @include for-tablet-landscape-up {
        margin-left: 24px;
      }
    }
    &__name {
      color: var(--white-color);
      margin-bottom: 6px;
      @include P2();
      @include for-tablet-landscape-up {
        margin-bottom: 8px;
        @include H6();
      }
    }
    &__position {
      color: var(--gray-color);
      margin-bottom: 14px;
      @include P4-light();
      @include for-tablet-portrait-up {
        margin-bottom: 24px;
      }
      @include for-tablet-landscape-up {
        margin-bottom: 22px;
      }
      @include for-desktop-up {
        @include P2-light();
      }
    }
    &__phone,
    &__email {
      color: var(--white-color);
      margin-bottom: 8px;
      text-decoration: none;
      @include P4();
      @include for-tablet-landscape-up {
        @include P2();
      }
    }
  }
}
</style>
