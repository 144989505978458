import Vue from 'vue'
import VueLazyload from 'vue-lazyload'

import PrimeVue from 'primevue/config'

import App from './App.vue'

import AOS from 'aos'
import 'aos/dist/aos.css'
import router from './router'

Vue.use(PrimeVue)
Vue.use(VueLazyload)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,

  created () {
    AOS.init({
      duration: 1000,
      mirror: false
    })
  }
}).$mount('#app')
