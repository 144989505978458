<template>
  <div class="consultation">
    <div class="wrapper">
      <div class="consultation__wrapper" style="overflow: hidden">
        <div class="consultation__image" data-aos="fade-right">
          <img
            loading="lazy"
            src="../assets/backgrounds/consultation-background.png"
            alt="Background"
            class="consultation__background"
          />
          <div class="consultation__image-item">
            <img
              src="../assets/images/consultation/iPhone-consultation.png"
              alt="iPhone"
              loading="lazy"
            />
          </div>
        </div>
        <div class="consultation__content">
          <div class="consultation__header" style="overflow: hidden">
            <div class="consultation__title" data-aos="fade-left">
              Остались вопросы? Свяжитесь с нами и получите бесплатную
              консультацию!
            </div>
          </div>
          <v-form class="consultation__form" data-aos="fade-left"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VForm from "../components/Form/VForm";

export default {
  name: "VConsultationForm",
  components: { VForm },
};
</script>

<style lang="scss">
.consultation {
  background: var(--light-color);
  position: relative;
  overflow: hidden;
  .wrapper {
    justify-content: center;
    @include for-tablet-portrait-up {
      justify-content: space-between;
    }
  }
  &__wrapper {
    display: flex;
    padding: 70px 10px;
    @include for-tablet-portrait-up {
      padding: 70px 15px;
    }
    @include for-tablet-landscape-up {
      padding: 90px 16px;
    }
    @include for-desktop-up {
      padding: 120px 0;
    }
  }
  &__image {
    display: none;
    width: 50%;
    @include for-tablet-portrait-up {
      display: flex;
      justify-content: center;
    }
    .consultation__background {
      position: absolute;
      z-index: 1;
      @include for-tablet-portrait-up {
        width: 380px;
        top: 101px;
      }
      @include for-tablet-landscape-up {
        width: 518px;
        height: 579px;
        top: 101px;
      }
    }
    &-item {
      z-index: 2;
      width: 239px;
      height: 476px;
      img {
        width: 239px;
        height: 476px;
        height: auto;
      }
    }
  }
  &__content {
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @include for-tablet-portrait-up {
      width: 50%;
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 26px;
    @include for-tablet-portrait-up {
      margin-bottom: 30px;
    }
    @include for-tablet-portrait-up {
      margin-bottom: 34px;
    }
    .consultation__title {
      color: var(--black-color);
      text-align: center;
      @include P1();
      @include for-tablet-landscape-up {
        @include H4();
      }
    }
  }
  &__form {
    width: 100%;
    display: flex;
    justify-content: center;
    form {
      width: 100%;
      max-width: 340px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include for-tablet-portrait-up {
        max-width: 100%;
        align-items: center;
      }
      .form__group {
        @include form-group();
      }
      .form__group.form__group-checkbox {
        display: flex;
        flex-direction: row;
      }
      .label {
        @include form-label($black-color);
      }
      .label-checkbox {
        color: var(--black-color);
        a {
          color: var(--black-color);
        }
      }
      .checkbox{
        border: 1px solid var(--super-light-gray-color);
      }
      input[type=text] {
        @include form-input($super-light-gray-color, $black-color);
      }
      textarea {
        @include form-textarea($super-light-gray-color, $black-color);
      }
      .form__group + .form__group {
        margin-top: 9px;
      }
    }
  }
}
</style>
