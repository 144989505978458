<template>
  <div class="statistics">
    <div class="wrapper">
      <div class="statistics__wrapper">
        <div class="statistics__header" data-aos="fade-up">
          <h6 class="statistics__subtitle subtitle">Статистика</h6>
          <h2 class="statistics__title title">ОциумСофт в цифрах</h2>
        </div>
        <div class="statistics__list">
          <div
            class="statistics-item"
            v-for="item in statistics_data"
            v-bind="item"
            :key="item.id"
            data-aos="fade-up"
          >
            <div class="statistics-item__icon">
              <img
                :src="require('../assets/icons/' + item.image)"
                :alt="item.text"
                loading="lazy"
              />
            </div>
            <p class="statistics-item__description">
              <span class="description-number">{{ item.startVal }}</span>
              <span class="description-text">{{ item.text }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VStatistics',
  props: ['number'],
  data: () => ({
    statistics_data: [
      {
        id: 1,
        image: 'person.svg',
        startVal: 23,
        endVal: 27,
        text: 'средний возраст сотрудников'
      },
      {
        id: 2,
        image: 'people_stat.svg',
        startVal: 16,
        endVal: 20,
        text: 'количество сотрудников'
      },
      {
        id: 3,
        image: 'calendar_stat.svg',
        startVal: 2016,
        endVal: 2020,
        text: 'год основания'
      },
      {
        id: 4,
        image: 'smile.svg',
        startVal: 1,
        endVal: 5,
        text: 'успешных проектов за первый год работы'
      },
      {
        id: 5,
        image: 'world.svg',
        startVal: 0,
        endVal: 4,
        text: 'языка, на которых говорим'
      }
    ]
  }),
  methods: {
    onScroll () {
      const elem = document.querySelector('.statistics')
      if (
        elem.offsetTop + elem.offsetHeight * 0.5 <
        window.scrollY + window.innerHeight
      ) {
        this.removeScrollHandler()
        const interval = setInterval(() => {
          for (let i = 0; i < this.statistics_data.length; i++) {
            if (
              this.statistics_data[i].startVal >=
              this.statistics_data[i].endVal - 1
            ) {
              clearInterval(interval)
            }
            this.statistics_data[i].startVal =
              parseInt(this.statistics_data[i].startVal) + 1
          }
        }, 100)
      }
    },
    removeScrollHandler () {
      window.removeEventListener('scroll', this.onScroll)
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
    this.$on('hook:beforeDestroy', this.removeScrollHandler)
    this.onScroll()
  }
}
</script>

<style lang="scss" scoped>
.statistics {
  background-color: var(--darkest-gray-color);
  &__wrapper {
    padding: 40px 26px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    @include for-tablet-portrait-up {
      padding: 50px 15px;
    }
    @include for-tablet-landscape-up {
      padding: 60px 15px;
    }
    @include for-desktop-up {
      padding: 70px 0;
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    @include for-tablet-portrait-up {
      margin-bottom: 36px;
    }
    @include for-tablet-landscape-up {
      margin-bottom: 40px;
    }
    @include for-desktop-up {
      margin-bottom: 50px;
    }
    .subtitle {
      color: var(--medium-gray-color);
    }
    .title {
      color: var(--white-color);
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 106px 130px 130px 130px 106px;
    justify-items: center;
    width: 100%;
    @include for-tablet-portrait-up {
      grid-template-columns: repeat(10, 1fr);
      grid-template-rows: repeat(2, 140px);
      width: 738px;
    }
    @include for-tablet-landscape-up {
      justify-content: center;
      grid-template-columns: 18.2% 19.2% 21.2% 22.7% 189px;
      grid-template-rows: repeat(1, 140px);
      width: 100%;
    }
    @include for-desktop-up {
      grid-template-columns: 209px 238px 266px 270px 217px;
      grid-template-rows: repeat(1, 140px);
    }
  }
  &-item {
    display: flex;
    width: 100%;
    max-width: 360px;
    flex-direction: column;
    padding-top: 24px;
    padding-bottom: 20px;
    padding-right: 30px;
    padding-left: calc(18px + 10%);
    border-bottom: 1px solid #44444f;
    @include for-tablet-portrait-up {
      max-width: 100%;
      padding: 0;
      border-bottom: none;
    }
    @include for-tablet-landscape-up {
      border-right: 1px solid #44444f;
    }
    &__icon {
      margin-bottom: 10px;
      img{
        width: 26px;
        height: 26px;
      }
    }
    &__description {
      display: flex;
      align-items: center;
      .description-number + .description-text {
        margin-left: 12px;
        @include for-tablet-portrait-up {
          margin-left: 14px;
        }
        @include for-tablet-landscape-up {
          margin-left: 8px;
        }
        @include for-desktop-up {
          margin-left: 12px;
        }
      }
      .description-number {
        font-family: Roboto !important;
        color: var(--white-color);
        font-size: 32px;
        font-weight: normal;
        font-style: normal;
        line-height: 48px;
        text-align: center;
        @include for-tablet-portrait-up {
          font-size: 36px;
        }
        @include for-desktop-up {
          font-size: 42px;
          line-height: 56px;
        }
      }
      .description-text {
        color: var(--white-color);
        @include P3();
        @include for-tablet-portrait-up {
          @include P5();
        }
        @include for-tablet-landscape-up {
          @include P3();
        }
        @include for-desktop-up {
          @include P2();
        }
      }
    }
  }
  &-item:nth-child(1) {
    padding-top: 0px;
    @include for-tablet-portrait-up {
      grid-column-start: 1;
      grid-column-end: 4;
      padding-right: 10px;
      padding-top: 29px;
      padding-bottom: 20px;
      border-right: 1px solid #44444f;
    }
    @include for-tablet-landscape-up {
      grid-column-start: 1;
      grid-column-end: 2;
      padding-right: 12px;
    }
    @include for-desktop-up {
      grid-column-start: 1;
      grid-column-end: 2;
      padding-top: 25px;
      padding-right: 25px;
    }
  }
  &-item:nth-child(2) {
    @include for-tablet-portrait-up {
      grid-column-start: 4;
      grid-column-end: 8;
      padding-left: 61px;
      padding-right: 61px;
      padding-top: 29px;
      padding-bottom: 20px;
      border-right: 1px solid #44444f;
    }
    @include for-tablet-landscape-up {
      grid-column-start: 2;
      grid-column-end: 3;
      padding-right: 12px;
      padding-left: 12px;
    }
    @include for-desktop-up {
      grid-column-start: 2;
      grid-column-end: 3;
      padding-top: 25px;
      padding-right: 25px;
      padding-left: 25px;
    }
  }
  &-item:nth-child(3) {
    @include for-tablet-portrait-up {
      // max-width: 235px;
      padding-left: 55px;
      grid-column-start: 8;
      grid-column-end: 11;
      padding-top: 29px;
      padding-bottom: 20px;
    }
    @include for-tablet-landscape-up {
      grid-column-start: 3;
      grid-column-end: 4;
      padding-right: 12px;
      padding-left: 12px;
    }
    @include for-desktop-up {
      grid-column-start: 3;
      grid-column-end: 4;
      padding-top: 25px;
      padding-right: 25px;
      padding-left: 25px;
    }
  }
  &-item:nth-child(4) {
    @include for-tablet-portrait-up {
      grid-column-start: 2;
      grid-column-end: 6;
      padding-left: 45px;
      padding-right: 60px;
      border-right: 1px solid #44444f;
      padding-top: 29px;
      padding-bottom: 20px;
    }
    @include for-tablet-landscape-up {
      grid-column-start: 4;
      grid-column-end: 5;
      padding-right: 12px;
      padding-left: 12px;
    }
    @include for-desktop-up {
      grid-column-start: 4;
      grid-column-end: 5;
      padding-top: 25px;
      padding-right: 25px;
      padding-left: 25px;
    }
  }
  &-item:nth-child(5) {
    border-bottom: none;
    @include for-tablet-portrait-up {
      padding-left: 60px;
      grid-column-start: 6;
      grid-column-end: 9;
      padding-top: 29px;
      padding-bottom: 20px;
    }
    @include for-tablet-landscape-up {
      grid-column-start: 5;
      grid-column-end: 6;
      padding-right: 0;
      padding-left: 17px;
      border-right: none;
    }
    @include for-desktop-up {
      grid-column-start: 5;
      grid-column-end: 6;
      padding-top: 25px;
      padding-right: 0;
      padding-left: 27px;
    }
  }
}
</style>
