<template>
  <div class="portfolio" id="portfolio">
    <div class="wrapper">
      <div class="portfolio__wrapper">
 <v-two-sliders
          :services_settings="projectsSettings"
          :images_settings="screenshotsSettings"
        >
          <template v-slot:text-slider>
            <div
              v-for="(slider_item, index) in projects"
              :key="index"
              class="projects-item"
            >
              <div class="projects-container" data-aos="fade-up">
                <div class="subtitle">
                  Portfolio
                </div>
                <div class="project-description">
                  {{ slider_item.project_description }}
                </div>
                <div class="project-title">
                  {{ slider_item.project_title }}
                </div>
                <TabView>
                  <TabPanel
                    v-for="tab in slider_item.project_tabs"
                    :key="tab.tab_title"
                    :header="tab.tab_title"
                    :style="{ cursor: 'default' }"
                  >
                    <div v-if="tab.tab_content">
                      <p v-for="(text, index) in tab.tab_content" :key="index">
                        {{ text }}
                      </p>
                    </div>
                    <ul class="list" v-if="tab.tab_content_list">
                      <li
                        v-for="(list_item, index) in tab.tab_content_list"
                        :key="index"
                      >
                        <span>{{ list_item }}</span>
                      </li>
                    </ul>
                    <div v-if="tab.tab_content_solution">
                      {{ tab.tab_content_solution }}
                    </div>
                    <div
                      class="additional-information"
                      v-if="tab.tab_content_team"
                    >
                      <span>
                        Team:
                      </span>
                      {{ tab.tab_content_team }}
                    </div>
                    <div
                      class="additional-information"
                      v-if="tab.tab_content_time"
                    >
                      <span>
                        Project duration:
                      </span>
                      {{ tab.tab_content_time }}
                    </div>
                    <div
                      class="additional-information"
                      v-if="tab.tab_content_technologies"
                    >
                      <span>
                        Technologies:
                      </span>
                      {{ tab.tab_content_technologies }}
                    </div>
                    <div class="other-information" v-if="tab.tab_content_other">
                      {{ tab.tab_content_other }}
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            </div>
          </template>
          <template v-slot:image-slider>
            <div
              class="screenshots-item"
              v-for="(slider_item, index) in screenshots"
              :key="index"
            >
              <div
                class="screenshots-container"
                :style="{ cursor: 'default' }"
                @mousedown="mouseDown"
                @mouseleave="mouseLeave"
                @mouseup="mouseUp"
                @mousemove.prevent="mouseMove"
              >
                <div
                  class="screenshot"
                  v-for="(image, index) in slider_item"
                  :key="index"
                >
                  <picture>
                    <source
                      :srcset="
                        require('../assets/images/portfolio/' +
                          image +
                          '.png') +
                          ' 1x, ' +
                          require('../assets/images/portfolio/' +
                            image +
                            '_2x' +
                            '.png') +
                          ' 2x'
                      "
                      type="image/png"
                      media="(min-width: 768px)"
                    />
                    <source
                      :srcset="
                        require('../assets/images/portfolio/' +
                          image +
                          '_mob' +
                          '.png') +
                          ' 1x, ' +
                          require('../assets/images/portfolio/' +
                            image +
                            '_mob_2x' +
                            '.png') +
                          ' 2x'
                      "
                      type="image/png"
                      media="(max-width: 767px)"
                    />
                    <img
                      :src="
                        require('../assets/images/portfolio/' + image + '.png')
                      "
                      loading="lazy"
                      alt="Screenshot"
                    />
                  </picture>
                </div>
              </div>
            </div>
          </template>
        </v-two-sliders>
      </div>
    </div>
  </div>
</template>

<script>
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import VTwoSliders from "./Carousel/VTwoSliders";
export default {
  components: { VTwoSliders, TabView, TabPanel },
  name: "VPortfolio",
  data() {
    return {
      projects: [
        {
          project_description: "Мобильное приложение для B2B продаж",
          project_title: "Бурштат, оптовая торговля",
          project_tabs: [
            {
              tab_title: "Клиент",
              tab_content: [
                "Бурштат – импортёр европейских брендов косметики (Ziaja, YOPE), средств личной гигиены (ABENA, HUMBLE), бытовой химии (SANO, BURTI) и товаров для дома.",
                "Компания занимается оптовой торговлей на территории Беларуси, торговые представители работают во всех основных регионах страны."
              ]
            },
            {
              tab_title: "Задача",
              tab_content: [
                "Для увеличения объёма продаж клиент решил упростить процесс размещения заказов для оптовых покупателей и наладить их своевременное оповещение обо всех новинках ассортимента, поступлениях товара, акциях и распродажах."
              ]
            },
            {
              tab_title: "Решение",
              tab_content: [
                'Простое в использовании мобильное приложение, благодаря которому оптовые покупатели могут самостоятельно оформлять заказы, получать информацию о новинках, как только они появляются на складе, и быть в курсе всех  "горячих" акционных предложений.',
                "Приложение уже успело доказать свою эффективность. Через полгода использования количество заявок в расчёте на одного клиента выросло на 50%."
              ]
            },
            {
              tab_title: "Ресурсы",
              tab_content_team:
                "8 человек (backend и frontend разработчики, QA)",
              tab_content_time:
                "первая активная фаза заняла 6 месяцев, работа над проектом продолжается в настоящее время",
              tab_content_technologies:
                "backend - Golang, frontend - Flutter, frontend website - HTML5 + JavaScript",
              tab_content_other:
                "Приложение создано на основе собственного автоматизированного решения для B2B и B2C продаж  – Otium E-commerce"
            }
          ]
        },
        {
          project_description:
            "Мобильное решение для учёта и контроля рабочего времени сотрудников",
          project_title: "Бурштат, оптовая торговля",
          project_tabs: [
            {
              tab_title: "Клиент",
              tab_content: [
                "Второй (после успешного запуска мобильного приложения для B2B продаж) совместный проект с компанией Бурштат.",
                "Бурштат – импортёр европейских брендов косметики (Ziaja, YOPE), средств личной гигиены (ABENA, HUMBLE), бытовой химии (SANO, BURTI) и товаров для дома."
              ]
            },
            {
              tab_title: "Задача",
              tab_content: [
                "Клиент обратился к нам с запросом разработать решение, которое бы позволило эффективно назначать задачи, оценивать результаты работы, следить за продуктивностью и повышать мотивацию сотрудников, которые занимаются ручным трудом."
              ]
            },
            {
              tab_title: "Решение",
              tab_content: [
                "Мобильное решение для учёта и контроля рабочего времени сотрудников с функцией разграничения прав доступа.",
                "С помощью решения руководители могут следить за активностью сотрудников в течение рабочего дня (приход, уход, перерывы и т.д.), назначать задачи и устанавливать измеримые KPI для них, генерировать и скачивать отчёты, а работники – видеть сразу весь объём работы на день, планировать время и самостоятельно рассчитывать свою зарплату и бонусы.",
                "Важно отметить, что система не позволит начать рабочий день, если сотрудник не находится в корректной рабочей локации."
              ]
            },
            {
              tab_title: "Ресурсы",
              tab_content_team:
                "4 человека (backend разработчик, frontend разработчик, QA, product owner)",
              tab_content_time: "3 месяца (первая версия)",
              tab_content_technologies:
                "backend - Golang, frontend - Flutter, frontend website - HTML5 + JavaScript",
              tab_content_other:
                "Приложение создано на основе собственного облачного решения для контроля и учёта рабочего времени  – Otium Analytics"
            }
          ]
        },
        {
          project_description:
            "Интернет-магазин + мобильное приложение для B2C продаж",
          project_title: 'Сеть магазинов косметики и бытовой химии "Мой"',
          project_tabs: [
            {
              tab_title: "Клиент",
              tab_content: [
                "Сеть магазинов «Мой» специализируется на розничной торговле бытовой химией, косметикой и товарами для дома, в том числе уникальными товарами из разных стран мира. В ассортименте можно найти и продукты питания.",
                "На момент обращения к нам сеть состояла из 4-х торговых объектов в городе Минске."
              ]
            },
            {
              tab_title: "Задача",
              tab_content: [
                "В целях расширения бизнеса клиент решил не останавливаться только на офлайн продажах и открыть направление онлайн продаж.",
                "Также было важно иметь возможность собирать информацию о своих покупателях, чтобы совершенствовать качество услуг и запускать результативные рекламные кампании в Интернете."
              ]
            },
            {
              tab_title: "Решение",
              tab_content: [
                "Для максимального охвата покупателей наша команда разработала сразу два инструмента онлайн продаж: интернет-магазин и мобильное приложение.",
                "В обоих инструментах реализованы функции, соответствующие бизнес-запросу клиента: категории и подкатегории товаров в каталоге, фильтры, личный кабинет пользователя, панель администратора, регистрация, авторизация и аутентификация, промокоды, поиск, интеграция с 1С:Бухгалтерия, онлайн оплата, системы аналитики (Google Analytics и Яндекс Метрика)"
              ]
            },
            {
              tab_title: "Ресурсы",
              tab_content_team:
                "8 человек (backend и frontend разработчики, QA)",
              tab_content_time:
                "первая активная фаза заняла 6 месяцев, работа над проектом продолжается в настоящее время",
              tab_content_technologies:
                "backend - Golang, frontend - Flutter, frontend website - HTML5 + JavaScript",
              tab_content_other:
                "Приложение создано на основе собственного автоматизированного решения для B2B и B2C продаж  – Otium E-commerce"
            }
          ]
        }
      ],
      screenshots: [
        [
          "burshtat_b2b_portfolio_1",
          "burshtat_b2b_portfolio_2",
          "burshtat_b2b_portfolio_3",
          "burshtat_b2b_portfolio_4",
          "burshtat_b2b_portfolio_5",
          "burshtat_b2b_portfolio_6"
        ],
        [
          "burshtat_mob_portfolio_1",
          "burshtat_mob_portfolio_2",
          "burshtat_mob_portfolio_3",
          "burshtat_mob_portfolio_4",
          "burshtat_mob_portfolio_5",
          "burshtat_mob_portfolio_6"
        ],
        [
          "moishop_portfolio_1",
          "moishop_portfolio_2",
          "moishop_portfolio_3",
          "moishop_portfolio_4",
          "moishop_portfolio_5",
          "moishop_portfolio_6"
        ]
      ],
      screenshotsSettings: {
        dots: false,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 1,
        variableWidth: true,
        touchMove: false,
        arrows: false
      },
      projectsSettings: {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        fade: true,
        swipe: false,
        touchMove: false,
        arrows: true
      },
      isDown: false,
      scrollLeft: 0,
      startX: 0,
      startY: 0,
      scrollTop: 0
    };
  },
  methods: {
    mouseDown: function(event) {
      let slider = event.currentTarget;
      slider.style.cursor = "grabbing";
      this.isDown = true;
      this.startX = event.pageX - slider.offsetLeft;
      this.scrollLeft = slider.scrollLeft;
      this.startY = event.pageY - slider.offsetTop;
      this.scrollTop = slider.scrollTop;
    },
    mouseLeave: function(event) {
      let slider = event.currentTarget;
      this.isDown = false;
      slider.style.cursor = "default";
    },
    mouseUp: function(event) {
      let slider = event.currentTarget;
      this.isDown = false;
      slider.style.cursor = "default";
    },
    mouseMove: function(event) {
      let slider = event.currentTarget;
      if (!this.isDown) return;
      let x = event.pageX - slider.offsetLeft;
      let walk = x - this.startX;
      slider.scrollLeft = this.scrollLeft - walk;
      let y = event.pageY - slider.offsetTop;
      let walkY = y - this.startY;
      slider.scrollTop = this.scrollTop - walkY;
    }
  }
};
</script>

<style lang="scss">
.portfolio {
  background: var(--light-color);
  overflow: hidden;
  @include for-tablet-portrait-up() {
    height: 704px;
  }
  @include for-desktop-up() {
    height: 800px;
  }
  .wrapper {
    @include for-tablet-portrait-up() {
      height: 100%;
    }
  }
  &__wrapper {
    padding: 70px 0 42px 10px;
    @include for-tablet-portrait-up() {
      padding: 0 15px;
      width: 100%;
      height: 100%;
    }
    @include for-tablet-landscape-up() {
      padding: 0 16px;
    }
    @include for-desktop-up() {
      padding: 0;
    }
    .subtitle {
      color: var(--light-gray-color);
      margin-bottom: 16px;
      @include for-tablet-portrait-up() {
        margin-bottom: 14px;
      }
      @include for-tablet-landscape-up() {
        margin-bottom: 16px;
      }
      @include for-desktop-up() {
        margin-bottom: 20px;
      }
    }
    .project-description {
      color: var(--black-color);
      margin-bottom: 20px;
      @include P1();
      @include for-desktop-up() {
        @include H4();
      }
    }
    .project-title {
      @include P6();
      display: inline-block;
      color: var(--dark-blue-color);
      padding: 4px 13px;
      border-radius: 20px;
      text-align: center;
      background: var(--super-light-blue-color);
      margin-bottom: 60px;
    }
    .sliders__container {
      width: 100vw;
      max-width: 100vw;
      min-width: 0;
      display: flex;
      flex-direction: column;
      @include for-tablet-portrait-up() {
        flex-direction: row;
        justify-content: space-between;
        height: 100%;
        width: 100%;
      }
      .sliders__container-item.text {
        width: calc(100vw - 25px);
        height: 100%;

        @include for-tablet-portrait-up {
          width: 70%;
        }
        @include for-tablet-landscape-up {
          width: 55%;
        }
        @include for-desktop-up {
          width: 43%;
        }
      }
      .text-slider {
        max-width: calc(100vw - 25px);
        @include for-tablet-portrait-up() {
          padding-top: 70px;
          max-width: 100%;
          height: auto;
        }
        @include for-desktop-up() {
          padding-top: 100px;
        }
        .slick {
          &-track {
            height: auto !important;
          }
          &-slide {
            height: 0;
            max-width: calc(100vw - 25px);
            visibility: hidden;
          }
          &-active {
            height: auto;
            visibility: visible;
          }
          &-arrow {
            @include carousel__btn-sm();
            position: absolute;
            top: calc(100% + 28px);
            @include for-tablet-portrait-up {
              top: calc(100% + 50px);
            }
            &.slick-prev {
              left: 0;
            }
            &.slick-next {
              left: 40px;
              @include for-tablet-portrait-up {
                left: 58px;
              }
            }
          }
        }
        .projects-item {
          outline: none;
          &:active {
            outline: none;
          }
          &:focus {
            outline: none;
          }
        }
      }
      .image-slider {
        min-width: 0;
        margin-top: 72px;
        // width: 100%;
        @include for-tablet-portrait-up() {
          margin-top: 0;
          width: 205px;
        }
        @include for-tablet-landscape-up() {
          width: 410px;
        }
        @include for-desktop-up() {
          width: 685px;
        }
        .slick {
          &-track {
            display: flex;
            align-items: flex-start;
          }
          &-list {
            overflow: hidden;
            @include for-desktop-up() {
              width: 685px;
            }
          }
          &-slide {
            padding: 0 5px;
            height: 100%;
            outline: none;
          }
        }
        .screenshots-item {
          width: calc(100vw - 10px);
          max-width: calc(100vw - 10px);
          min-width: calc(100vw - 10px);
          outline: none;
          &:active {
            outline: none;
          }
          &:focus {
            outline: none;
          }
          @include for-tablet-portrait-up() {
            width: 205px;
            max-width: 205px;
          }
          @include for-tablet-landscape-up() {
            width: 410px;
            max-width: 410px;
          }
          @include for-desktop-up() {
            width: 685px;
            max-width: 685px;
          }
          .screenshots-container {
            display: flex;
            width: calc(100vw - 10px);
            max-width: calc(100vw - 10px);
            white-space: nowrap;
            overflow-y: hidden;
            overflow-x: scroll;
            scroll-behavior: auto;
            -ms-overflow-style: none;
            overflow: -moz-scrollbars-none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
              width: 0;
            }
            @include for-tablet-portrait-up() {
              overflow-y: scroll;
              overflow-x: hidden;
              width: 205px;
              max-width: 205px;
              padding: 0 10px;
              flex-direction: column;
              height: 704px;
            }
            @include for-tablet-landscape-up() {
              overflow-x: scroll;
              -ms-overflow-style: none;
              overflow: -moz-scrollbars-none;
              scrollbar-width: none;
              &::-webkit-scrollbar {
                width: 0;
              }
              width: 410px;
              max-width: 410px;
              height: 722px;
              flex-direction: row;
              flex-wrap: wrap;
              padding: 0;
            }
            @include for-desktop-up() {
              overflow-x: scroll;
              -ms-overflow-style: none;
              overflow: -moz-scrollbars-none;
              scrollbar-width: none;
              &::-webkit-scrollbar {
                width: 0;
              }
              width: 685px;
              max-width: 685px;
              height: 817px;
            }
          }
          .screenshot {
            position: relative;
            width: 185px;
            min-width: 185px;
            max-width: 185px;
            min-height: 367px;
            height: 367px;
            max-height: 367px;
            outline: none;
            overflow: hidden;
            @include for-tablet-landscape-up() {
              width: 190px;
              min-width: 190px;
              max-width: 190px;
              min-height: 389px;
              height: 389px;
              max-height: 389px;
              margin-bottom: 20px;
              &:nth-child(2n + 1) {
                margin-right: 20px;
              }
              &:nth-child(2) {
                margin-top: 100px !important;
              }
              &:nth-child(3) {
                margin-top: -100px !important;
              }
              &:nth-child(5) {
                margin-top: -100px !important;
              }
            }
            @include for-desktop-up() {
              width: 213px;
              min-width: 213px;
              max-width: 213px;
              height: 429px;
              max-height: 429px;
              min-height: 429px;
              margin-right: 20px;
              &:nth-child(3n + 3) {
                margin-right: 0;
              }
              &:nth-child(2) {
                margin-top: 100px !important;
              }
              &:nth-child(3) {
                margin-top: 0 !important;
              }
              &:nth-child(4) {
                margin-top: -100px !important;
              }
              &:nth-child(5) {
                margin-top: 0 !important;
              }
              &:nth-child(6) {
                margin-top: -100px !important;
              }
            }
            img,
            source {
              height: 100%;
              max-width: 100%;
              object-fit: cover;
              object-position: center;
              position: absolute;
              top: 0;
              left: 0;
              @include for-tablet-portrait-up {
                width: 100%;
              }
            }
          }
          .screenshot + .screenshot {
            margin-left: 30px;
            @include for-tablet-portrait-up() {
              margin-left: 0px;
              margin-top: 20px;
            }
            @include for-tablet-landscape-up() {
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
}
.projects-item {
  .p-tabview .p-tabview-nav {
    background: transparent;
  }
  .p-tabview-nav {
    flex-wrap: nowrap;
  }
  .p-tabview .p-tabview-nav {
    white-space: nowrap;
    border: none;
    padding-bottom: 10px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  .p-tabview {
    .p-tabview-nav {
      li + li {
        margin-left: 17px;
        @include for-tablet-landscape-up {
          margin-left: 40px;
        }
        @include for-desktop-up {
          margin-left: 60px;
        }
      }
    }
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: transparent;
    color: var(--gray-color);
    @include P3();
    border: none;
    padding: 0 0 15px 0;
    @include for-tablet-portrait-up() {
      padding-right: 0;
      padding-left: 0;
    }
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: transparent;
    // border-color: none;
    // border-bottom: 2px solid #43a8f8;
    border: none;
    color: var(--black-color);
    @include P3-bold();
    position: relative;
    // @include header-link();
    &::after {
      display: block;
      position: absolute;
      top: 25px;
      left: 0;
      right: 0;
      width: 50%;
      height: 2px;
      border-radius: 4px;
      margin-left: auto;
      margin-right: auto;
      content: "";
      background: #43a8f8;
      box-shadow: 0px 1px 3px rgba(67, 168, 248, 0.6);
      transition: width 0.3s ease-out;
    }
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
  }
  .p-tabview .p-tabview-panels {
    background: transparent;
    padding-top: 10px;
    @include P4();
    line-height: 20px;
    color: var(--black-color);
  }
  .additional-information {
    margin-bottom: 8px;
    span {
color: var(--dark-blue-color);
    }
  }
  .p-tabview-panel p + p {
    margin-top: 8px;
  }
  .other-information {
    color: var(--dark-gray-color);
    line-height: 20px;
    margin-top: 8px;
  }
  .p-tabview
    .p-tabview-nav
    li:not(.p-highlight):not(.p-disabled):hover
    .p-tabview-nav-link {
    background: transparent;
  }
  .list {
    margin-top: 8px;
    margin-bottom: 12px;
    list-style-type: disc !important;
    list-style-position: outside !important;
    color: var(--dark-blue-color);
    padding: 0 0 0 17px !important;
    li {
      margin-bottom: 10px;
      span {
        color: var(--black-color);
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
</style>
