<template>
  <div id="app" :class="{ nonscrolling: isNavOpen }">
    <v-header :links="menu_links" />
    <router-view />
    <v-modal @close="closeModal" v-show="isModalOpen">
      <template #header v-if="isRequestCorrect">
        Спасибо!
      </template>
      <template #header v-else>
        Ошибка!
      </template>
      <template #body v-if="isRequestCorrect">
        Ваше сообщение успешно отправлено! Мы свяжемся с вами в ближайшее время!
      </template>
      <template #body v-else>
        Пожалуйста, проверьте введенные данные!
      </template>
    </v-modal>
    <v-popup @close="closePopup" v-show="isCookiesOpen">
      <template #text>
        Уважаемый пользователь, Мы непрерывно улучшаем наш веб-сайт. С помощью
        Cookie-файлов мы получаем от вас необходимую информацию, а затем
        используем ее в целях улучшения работоспособности сайта, сбора
        статистической информации, а также в маркетинговых целях. Подробную
        информацию, а также порядок отказа от использования cookie можно
        получить
        <router-link to="/privacy-policy" class="footer__privacy_link">
          здесь.
        </router-link>
      </template>
    </v-popup>
    <v-footer :links="menu_links" />
  </div>
</template>

<script>
import VHeader from "./components/VHeader";
import VFooter from "./components/VFooter.vue";
import VPopup from "./components/Modal/VPopup";
import { mutations, store } from "./store/app/store";
import router from "./router/index";

export default {
  name: "App",
  components: {
    VModal: () => import("./components/Modal/VModal"),
    VPopup,
    VFooter,
    VHeader
  },
  data: () => ({
    menu_links: [
      {
        name: "Услуги",
        anchor: "#services"
      },
      {
        name: "О компании",
        anchor: "#about-company"
      },
      {
        name: "Портфолио",
        anchor: "#portfolio"
      },
      {
        name: "Контакты",
        anchor: "#managers"
      }
    ]
  }),
  computed: {
    isNavOpen() {
      return store.isNavOpen;
    },
    isModalOpen() {
      return store.isModalOpen;
    },
    isRequestCorrect() {
      return store.isRequestCorrect;
    },
    isCookiesOpen() {
      return store.isCookiesPopupOpen;
    }
  },
  methods: {
    closeModal() {
      router.push("/");
      mutations.closeModal();
      document.body.style.overflow = "auto";
    },
    closePopup() {
      mutations.closeCookiesPopup();
    }
  }
};
</script>

<style lang="scss"></style>
