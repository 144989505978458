<template>
  <div class="form" data-aos="fade-left">
    <form @submit.prevent="submit">
      <div class="form__group">
        <label class="label">Ваше имя</label>
        <input
          name="name"
          type="text"
          v-model.trim="form.name"
          placeholder="Имя"
        />
        <div class="error-message">
        </div>
      </div>
      <div class="form__group">
        <label class="label">Телефон</label>
        <input
          name="phone"
          type="text"
          v-model.trim="form.phone"
          placeholder="Телефон"
        />
        <div class="error-message">
        </div>
      </div>
      <div class="form__group">
        <label class="label">E-mail</label>
        <input
          name="email"
          type="text"
          v-model.trim="form.email"
          placeholder="E-mail"
        />
        <div class="error-message">
        </div>
      </div>
      <div class="form__group">
        <label class="label">Комментарий</label>
        <textarea
          name="comment"
          v-model.trim="form.comment"
          placeholder="Комментарий"
          @input="textAreaAdjust"
          class="textarea"
        ></textarea>
        <div class="error-message">
        </div>
      </div>
      <div class="form__group form__group-checkbox">
        <input type="checkbox" class="checkbox" v-model="isChecked" />
        <label class="label-checkbox"
          >Я даю согласие на использование моих персональных данных в
          соответствии с
          <router-link to="/privacy-policy">
            Политикой конфиденциальности
          </router-link>
        </label>
      </div>
      <div class="form__button">
        <button type="submit" :disabled="!isChecked">
          <div class="button__label">Связаться с нами</div>
          <div class="button__circle-primary">
            <img
              loading="lazy"
              src="../../assets/icons/arrow-next.svg"
              class="arrow"
              alt="Next arrow"
            />
          </div>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import formsService from "../../services/forms.services";
export default {
  data: () => ({
    form: {
      name: "",
      phone: "",
      email: "",
      comment: ""
    },
    isChecked: false
  }),
  methods: {
    async submit() {
      await formsService.sendForm(
        this.form.name,
        this.form.phone,
        this.form.email,
        this.form.comment
      );
    },
    textAreaAdjust: function(e) {
      e.target.style.height = "auto";
      e.target.style.height = e.target.scrollHeight + "px";
    }
  },
  mounted() {
    let textarea = document.querySelectorAll(".textarea");
    textarea.forEach(item => {
      item.setAttribute(
        "style",
        "height:" + textarea.scrollHeight + "px;overflow-y:hidden;"
      );
    });
  }
};
</script>

<style lang="scss">
.checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  width: 26px;
  min-width: 26px;
  height: 26px;
  cursor: pointer;
  position: relative;
  &::before {
    content: url("../../assets/icons/checkbox.svg");
    position: absolute;
    right: 50%;
    bottom: 8px;
    transform: translate(50%, 50%);
    opacity: 0;
    transition: 0.1s ease-in;
  }
  &:checked::before {
    opacity: 1;
  }
}

.label-checkbox {
  @include P6();
  @include for-tablet-landscape-up() {
    @include P4();
  }
}
.checkbox + .label-checkbox {
  margin-left: 10px;
}
</style>
